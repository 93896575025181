<template>
    <div class="footer-intro-wrapper">
        <div class="footer-logo">
            <router-link @click.native="scrollToTop" to="/">
            <img :src="`/${logo}`" class="img-fluid" alt="logo">
            </router-link>
        </div>
        <div class="footer-desc">
            {{ desc }}
        </div>
    </div>
</template>

<script>
    export default {
        props: [ 'logo', 'desc' ],
        methods: {
            scrollToTop() {
                window.scrollTo(0, 0)
            }
        }
    };
</script>
<template>
    <div class="mobile-navigation header-maintitle">
        <nav class="offcanvas-navigation" id="offcanvas-navigation">
            <ul>
                <li>
                    <a href="/">HOME</a>
                </li>
                <li>
                    <a href="/about">ABOUT US</a>
                </li>
                <li class="menu-item-has-children">
                    <!-- Child Menu Start -->
                    <a href="/service">SERVICES</a>
                    <!-- <ul class="sub-menu">
                        <li>
                            <a href="/service-details/1">Construction Works</a>
                        </li>
                        <li class="menu-item-has-children">
                            <router-link to="">MEPF System</router-link>
                            <ul class="sub-menu">
                                <li>
                                <a href="/service-details/2">Mechanical & Ventilation System</a>
                            </li>
                            <li class="menu-item-has-children">
                                <router-link to="">Electrical System</router-link>
                                <ul class="sub-menu">
                                    <li><a href="/service-details/3">Medium Voltage</a></li>
                                    <li><a href="/service-details/4">Low Voltage</a></li>
                                </ul>
                            </li>
                            <li>
                                <a href="/service-details/5">Plumbing</a>
                            </li>
                            <li>
                                <a href="/service-details/6">Fire Fighting & Fire Surpression System</a>
                            </li>
                                <li><a href="/service-details/3">Medium Voltage</a></li>
                                <li><a href="/service-details/4">Low Voltage</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="/service-details/2">Mechanical & Ventilation System</a>
                        </li>
                        <li class="menu-item-has-children">
                            <router-link to="">Electrical System</router-link>
                            <ul class="sub-menu">
                                <li><a href="/service-details/3">Medium Voltage</a></li>
                                <li><a href="/service-details/4">Low Voltage</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="/service-details/5">Plumbing</a>
                        </li>
                        <li>
                            <a href="/service-details/6">Fire Fighting & Fire Surpression System</a>
                        </li>
                    </ul> -->
                    <!-- Child Menu end -->
                </li>
                <li class="menu-item-has-children">
                    <router-link to="/project-completed">PROJECTS</router-link>
                    <!-- <ul class="sub-menu">
                        <li class="menu-item-has-children">
                            <router-link to="">Construction Project</router-link>
                            <ul class="sub-menu">
                                <li><a href="/project-on-going">Project on Going</a></li>
                                <li><a href="/project-completed">Project Completed</a></li>
                                <li><a href="/renovation-project">Renovation Project</a></li>
                            </ul>
                        </li>
                        <li class="menu-item-has-children">
                            <router-link to="">M&E Project</router-link>
                            <ul class="sub-menu">
                                <li><a href="/mande-project-on-going">M&E Project on Going</a></li>
                                <li><a href="/mande-project-completed">M&E Project Completed</a></li>
                                <li><a href="/renovation-mande-project">Renovation M&E Project</a></li>
                            </ul>
                        </li>
                    </ul> -->
                </li>
                <li>
                    <a href="/csr">Cooperate Social Responsibility</a>
                </li>
                <li>
                    <a href="/contact">CONTACT US</a>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
    export default{
        name: 'MobileNavMenu',
        mounted() {
            const offCanvasNav = document.querySelector('#offcanvas-navigation');
            const offCanvasNavSubMenu = offCanvasNav.querySelectorAll('.sub-menu');
            const anchorLinks = offCanvasNav.querySelectorAll('a');
        
            for (let i = 0; i < offCanvasNavSubMenu.length; i++){
                offCanvasNavSubMenu[i].insertAdjacentHTML("beforebegin", "<span class='menu-expand'><i></i></span>");
            }
        
            const menuExpand = offCanvasNav.querySelectorAll('.menu-expand');
            const numMenuExpand = menuExpand.length;
        
            for (let i = 0; i < numMenuExpand; i++) {
                menuExpand[i].addEventListener("click", (e) => {sideMenuExpand(e)});
            }
        
            for (let i = 0; i < anchorLinks.length; i++) {
                anchorLinks[i].addEventListener("click", () => {closeMobileMenu()});
            }

            const sideMenuExpand = (e) => {
                e.currentTarget.parentElement.classList.toggle('active');
            }
            const closeMobileMenu = () => {
                const offcanvasMobileMenu = document.querySelector('#offcanvas-mobile-menu');
                offcanvasMobileMenu?.classList.remove('active');
            }
        }
    };
</script>

<style lang="scss">
    @import '../assets/scss/0-base/_variables.scss';

    /* offcanvas mobile menu */
    .mobile-navigation {
        padding: 30px 20px;
    }
    .offcanvas-navigation {
        & > ul {
            li {
                &.menu-item-has-children {
                    .sub-menu {
                        height: 0;
                        visibility: hidden;
                        opacity: 0;
                        transition: 0.3s;
                    }
                    &.active {
                        & > .sub-menu {
                            height: 100%;
                            visibility: visible;
                            opacity: 1;
                        }
                    }
                }
                & > a {
                    color: $white;
                    font-size: 14px;
                    padding: 10px 0;
                    display: block;
                    &:hover {
                        color: $white;
                    }

                }
            }
        }

        ul {
            &.sub-menu {
                margin-left: 25px;
                transition: 0.3s;
                & > li {
                    & > a {
                        color: $white;
                        font-size: 13px;
                        padding: 10px 0;
                        &:hover {
                            color: $white;
                        }
                    }
                }
            }
            li {
                a {
                    text-transform: uppercase;
                }

                &.menu-item-has-children {
                    position: relative;
                    display: block;
                    a {
                        display: block;
                    }

                    &.active {
                        & > .menu-expand {
                            i {
                                &:before {
                                    transform: rotate(0);
                                }
                            }
                        }
                    }

                    .menu-expand {
                        position: absolute;
                        right: auto;
                        left: 95%;
                        top: -5px;
                        width: 30px;
                        height: 50px;
                        line-height: 50px;
                        cursor: pointer;
                        text-align: center;

                        i {

                            display: block;
                            margin-top: 27px;
                            border-bottom: 1px solid $white;
                            position: relative;
                            width: 10px;
                            transition: all 250ms ease-out;
                            &:before {
                                width: 100%;
                                content: "";
                                border-bottom: 1px solid $white;
                                display: block;
                                position: absolute;
                                top: 0;
                                transform: rotate(90deg);
                            }
                        }
                    }
                }
            }
        }
    }
</style>
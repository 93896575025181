<template>
    <!--====================  header area ====================-->
    <div class="header-area">
        <div class="header-area__desktop header-area__desktop--default">

            <!--=======  header top bar  =======-->
            <div class="header-top-bar">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-4">
                            <!-- top bar left -->
                            <div class="top-bar-left-wrapper">
                                <div class="social-links social-links--white-topbar d-inline-block">
                                    <ul>
                                        <li>
                                            <a title="Facebook" href="https://www.facebook.com/profile.php?id=100063564487641" target="_blank"><i class="zmdi zmdi-facebook"></i></a>
                                        </li>
                                        <li>
                                            <a title="Telegram" href="tel:023722733"><i class="fab fa-telegram-plane"></i></a>
                                        </li>
                                        <li>
                                            <a title="Whatsapp" href="tel:023722733"><i class="fab fa-whatsapp"></i></a>
                                        </li>
                                        <li>
                                            <a title="Mail" href="mailto:info@stscambodia.com.kh"><i class="far fa-envelope"></i></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-8">
                            <!-- top bar right -->
                            <div class="top-bar-right-wrapper">
                                <router-link to="/Contact" class="ht-btn ht-btn--default d-inline-block">GET A QUOTE</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--=======  End of header top bar  =======-->

            <!--=======  header info area  =======-->
            <div class="header-info-area">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-12">
                            <div class="header-info-wrapper align-items-center">
                                <!-- logo -->
                                <div class="logo">
                                    <router-link to="/">
                                        <img src="/assets/img/logo/stslogo.png" class="img-fluid" alt="Brand Logo">
                                    </router-link>
                                </div>

                                <!-- header contact info -->
                                <div class="header-contact-info">
                                    <div class="header-info-single-item">
                                        <div class="header-info-single-item__icon">
                                            <i class="fas fa-mobile-alt"></i>
                                        </div>
                                        <div class="header-info-single-item__content">
                                            <h6 style="color: #ed1d25" class="header-info-single-item__title">Hotline</h6>
                                            <p class="header-info-single-item__subtitle">
                                                <a style="color: #ed1d25" href="tel:081716716">081 716 716</a>
                                            </p>
                                        </div>
                                    </div>
                                    <div class="header-info-single-item">
                                        <div class="header-info-single-item__icon">
                                            <i class="fas fa-map-marked-alt"></i>
                                        </div>
                                        <div class="header-info-single-item__content">
                                            <h6 style="color: #ed1d25" class="header-info-single-item__title">Address</h6>
                                            <div style="color: #ed1d25" class="header-info-single-item__subtitle"><span>No 568E0, Street 70,</span><br><span>Toul Sangkae 1, Russey Keo,</span><br><span>Phnom Penh, CAMBODIA.</span></div>
                                            <!-- <p>No 568E0, Street 70, Toul Sangkae 1,</p><br><p>Russey Keo, Phnom Penh,</p><br><p>Kingdom of Cambodia.</p> -->
                                        </div>
                                    </div>
                                </div>

                                <!-- mobile menu -->
                                <div class="mobile-navigation-icon" id="mobile-menu-trigger" @click="mobiletoggleClass('addClass', 'active')">
                                    <i></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--=======  End of header info area =======-->

            <!--=======  header navigation area  =======-->
            <fixed-header>
                <div class="header-navigation-area header-maintitle default-bg">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <!-- header navigation -->
                                <div class="header-navigation header-navigation--header-default position-relative">
                                    <div class="header-navigation__nav position-static">
                                        <nav>
                                            <ul>
                                                <li>
                                                    <router-link style="font-size: 15px" @click.native="scrollToTop" to="/">HOME</router-link>
                                                </li>
                                                <li>
                                                    <!-- <a @click.native="scrollToTop" href="/about">ABOUT</a> -->
                                                    <router-link style="font-size: 15px" @click.native="scrollToTop" to="/about">ABOUT US</router-link>
                                                </li>

                                                <li>
                                                    <!-- <a @click.native="scrollToTop" href="/service">SERVICE</a> -->
                                                    <router-link style="font-size: 15px" @click.native="scrollToTop" to="/service">SERVICES</router-link>
                                                        <!-- Child Menu Service Start -->
                                                    <!-- <ul class="submenu">
                                                         <li>
                                                            <a href="/service-details/1">Construction Works</a>
                                                        </li>
                                                        <li class="has-children has-children--multilevel-submenu">
                                                            <router-link to="">MEPF System</router-link>
                                                            <ul class="submenu">
                                                                <li>
                                                                    <a href="/service-details/2">Mechanical & Ventilation System</a>
                                                                </li>
                                                                <li @click="visible = !visible">
                                                                    <router-link to="">
                                                                        Electrical System
                                                                        <span v-if="visible" aria-hidden="true" class="ml-5">-</span>
                                                                        <span v-else aria-hidden="true" class="ml-5">+</span>
                                                                    </router-link>
                                                                </li>
                                                                <b-collapse id="collapse-4" v-model="visible" class="mt-2 ml-4">
                                                                    <ul>
                                                                        <li><a href="/service-details/3">Medium Voltage</a></li>
                                                                        <li><a href="/service-details/4">Low Voltage</a></li>
                                                                    </ul>
                                                                </b-collapse>
                                                                <li>
                                                                    <a href="/service-details/5">Plumbing</a>
                                                                </li>
                                                                <li>
                                                                    <a href="/service-details/6">Fire Fighting & Fire Surpression System</a>
                                                                </li>
                                                                <li><a href="/service-details/3">Medium Voltage</a></li>
                                                                <li><a href="/service-details/4">Low Voltage</a></li>
                                                            </ul> -->
                                                        <!-- </li> -->
                                                        <!-- <li>
                                                            <a href="/service-details/2">Mechanical & Ventilation System</a>
                                                        </li>
                                                        <li class="has-children has-children--multilevel-submenu">
                                                            <router-link to="">Electrical System</router-link>
                                                            <ul class="submenu">
                                                                <li><a href="/service-details/3">Medium Voltage</a></li>
                                                                <li><a href="/service-details/4">Low Voltage</a></li>
                                                            </ul>
                                                        </li>
                                                        <li>
                                                            <a href="/service-details/5">Plumbing</a>
                                                        </li>
                                                        <li>
                                                            <a href="/service-details/6">Fire Fighting & Fire Surpression System</a>
                                                        </li> -->
                                                    <!-- </ul> -->
                                                    <!-- Child Menu Service End -->
                                                </li>

                                                <li>
                                                    <!-- <a @click.native="scrollToTop" href="/project">PROJECT</a> -->
                                                    <router-link style="font-size: 15px" @click.native="scrollToTop" to="/project-completed">PROJECTS</router-link>
                                                    <!-- <ul class="submenu">
                                                        <li class="has-children has-children--multilevel-submenu">
                                                            <router-link @click.native="scrollToTop" to="/project-completed">Construction Project</router-link>
                                                            <ul class="submenu">
                                                                <li><a href="/project-on-going">Project on Going</a></li>
                                                                <li><a href="/project-completed">Project Completed</a></li>
                                                                <li><a href="/renovation-project">Renovation Project</a></li>
                                                            </ul>
                                                        </li>
                                                        <li class="has-children has-children--multilevel-submenu">
                                                            <router-link style="font-size: 15px" @click.native="scrollToTop" to="/mande-project-completed">M&E Project</router-link>
                                                            <ul class="submenu">
                                                                <li><a href="/mande-project-on-going">M&E Project on Going</a></li>
                                                                <li><a href="/mande-project-completed">M&E Project Completed</a></li>
                                                                <li><a href="/renovation-mande-project">Renovation M&E Project</a></li>
                                                            </ul>
                                                        </li>
                                                    </ul> -->
                                                </li>
                                                <li>
                                                    <!-- <a @click.native="scrollToTop" href="/contact">CONTACT</a> -->
                                                    <router-link style="font-size: 15px" @click.native="scrollToTop" to="/csr">CSR</router-link>
                                                </li>
                                                <li>
                                                    <!-- <a @click.native="scrollToTop" href="/contact">CONTACT</a> -->
                                                    <router-link style="font-size: 15px" @click.native="scrollToTop" to="/contact">CONTACT US</router-link>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fixed-header>
            <!--=======  End of header navigation area =======-->
        </div>
    </div>
    <!--====================  End of header area  ====================-->
</template>

<script>
    import data from '../data/project.json'
    import FixedHeader from 'vue-fixed-header'
    export default {
        components: {
            FixedHeader,
            data,
        },
        data() {
            return {
                visible: false
            }
        },
        methods: {
            // offcanvas mobilemenu
            mobiletoggleClass(addRemoveClass, className) {
                const el = document.querySelector('#offcanvas-menu');
                if (addRemoveClass === 'addClass') {
                    el.classList.add(className);
                } else {
                    el.classList.remove(className);
                }
            },
            scrollToTop() {
                window.scrollTo(0, 0);
            },
        },
    };
</script>

<style lang="scss">
.vue-fixed-header--isFixed {
    left: 0;
    top: 0;
    width: 100vw;
    z-index: 999;
    position: fixed;
    box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.2);
    animation: 900ms cubic-bezier(0.2, 1, 0.22, 1) 0s normal none 1 running fadeInDown;
}

.bg-color {
    background: #ee262d;
}

// .item__icon {
//     width: 50px;
//     height: 50px;
//     margin-right: 15px;
//     border: 1px solid #ed1d25;
//     color: #ed1d25;
//     display: inline-flex;
//     align-items: center;
//     justify-content: center;
//     border-radius: 50%;
//     text-decoration: none;
// }

// .item__icon i {
//     font-size: 28px;
// }
</style>